.accordion {

	li {
		@include divider(before);

		&:last-of-type {
			@include divider(after);
		}

		.invert-color & {

			@include divider(before, inverse);

			&:last-of-type {
				@include divider(after, inverse);
			}
		}
	}
}

.accordion-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
	padding-top: $accordion-item--padding-v;
	padding-bottom: $accordion-item--padding-v;
	padding-left: $accordion-item--padding-h;
	padding-right: $accordion-item--padding-h;
    cursor: pointer;

    span {
        width: calc(100% - (#{$accordion-icon--size} * 2));
    }
    // color: white;
}

.accordion-content {
	padding-left: $accordion-item--padding-h;
	padding-right: $accordion-item--padding-h;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: max-height .15s ease-in-out,opacity .15s;

    .is-active & {
        opacity: 1;
    }

    p:last-child {
        margin-bottom: $accordion-item--padding-v;
    }
    // color: white;
}

.accordion-icon {
    position: relative;
    width: $accordion-icon--size;
    height: $accordion-icon--size;

    &::before,
    &::after {
        content: '';
        position: absolute;
        background: color-icon(accordion);
		border-radius: $accordion-icon--radius;
        transition: transform .25s ease-out;

		.invert-color & {
			background: color-icon(accordion-inverse);
		}
    }

    &::before {
        top: 0;
        left: 50%;
        width: $accordion-icon--thickness;
        height: 100%;
        margin-left: -($accordion-icon--thickness / 2);
    }

    &::after {
        top: 50%;
        left: 0;
        width: 100%;
        height: $accordion-icon--thickness;
        margin-top: -($accordion-icon--thickness / 2);
    }

    .is-active & {
        cursor: pointer;

        &::before {
            transform: rotate(90deg);
        }

        &::after {
            transform: rotate(180deg);
        }
    }
}
