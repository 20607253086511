/* researchCarouselStyles.css */
.carousel-slide {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 10px;
  height: 600px; /* Further increased height */
  display: flex;
  flex-direction: column;
  padding-right: 10px;
  padding-left: 10px;
}

.collage-container {
  height: 250px; /* Reduced height for image section */
  margin-bottom: 20px;
}

.collage {
  height: 100%;
  display: flex;
  gap: 10px;
}

.left-panel {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
}

.right-panel {
  flex: 1;
  height: 100%;
}

.small-image {
  flex: 1;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
}

.large-image {
  height: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
}

.small-image img,
.large-image img {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Changed from cover to contain */
  border-radius: 8px;
}

.text-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 10px 0;
}

.title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  line-height: 1.3;
}

.abstract {
  font-size: 14px;
  color: #666;
  line-height: 1.6;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* Increased from 3 to 4 lines */
  -webkit-box-orient: vertical;
}

/* Override default slick arrows to prevent duplicates */
.slick-prev,
.slick-next {
  display: none !important;
}

/* Carousel navigation arrows */
.custom-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.custom-next-arrow {
  right: -20px;
}

.custom-prev-arrow {
  left: -20px;
}

.custom-arrow:before {
  font-family: 'slick';
  font-size: 20px;
  color: #333;
  opacity: 0.8;
}

.custom-next-arrow:before {
  content: '→';
}

.custom-prev-arrow:before {
  content: '←';
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .carousel-slide {
    height: 550px;
  }
  
  .collage-container {
    height: 200px;
  }
  
  .custom-arrow {
    width: 30px;
    height: 30px;
  }
}

@media (max-width: 480px) {
  .carousel-slide {
    height: 500px;
  }
  
  .collage-container {
    height: 180px;
  }
}
  