.hero {
    &.has-bg-color {
		position: relative;
		background-color: get-color(dark, 1);
        z-index: auto;
    }
}

.hero-background {
	background-size: cover;
	background-repeat: no-repeat;
	position: relative;  /* keep it relative */
	width: 100%;  /* set width to 100% */
	height: 100vh;  /* set height to 100vh */
	z-index: 1;
}

.program-offering-background {
	background-size: cover;
	background-repeat: no-repeat;
	position: relative;  /* keep it relative */
	width: 100%;  /* set width to 100% */
	z-index: 1;
}

.hero-container {
	width: 100%;
	margin: 0;
	padding-left: $container--padding__mobile;
	padding-right: $container--padding__mobile;

	@include media( '>small' ) {
		padding-left: ( $container--padding__desktop * 7 );
		padding-right: $container--padding__desktop * 7;
	}
}

.hero-content {
	width: 50%;
}

.hero-container {
	max-width: $container--width + ( $container--padding__desktop * 2 );
}

[class*=hero-container] {

	[class*=hero-container] {
		padding-left: 0;
		padding-right: 0;
	}
}
  
.scrollable-content {
	overflow-y: auto; /* Enables vertical scrolling if content overflows */
	max-height: 100vh; /* Optional: sets the maximum height to the viewport height */
}

.rocket-container {
	position: absolute;
	bottom: 20%;
	left: 60%;
	z-index: 10;
}
  
.rocket {
	display: block;
	//size relative to screen
	width: 60%;
	
	z-index: 11;
}
  
.rocket-exhaust {
	position: absolute;
	bottom: 0px; /* Adjust based on the rocket size */
	//display: block;
	//size relative to screen
	width: 200px;
	z-index: 11;
}
  
.hero {
	position: relative;
	z-index: 2;
	color: white; /* Use this if you want your text color to be white */
}

.hero-button {
	padding: auto;
	font-size: 0.75rem;
}

.hero-inner {
	padding-bottom: 168px;
	font-size: 1.0rem; /* Adjust as needed */
}

/* Smaller font size for screens narrower than 1000px */
@media (max-width: 1000px) {
    .hero-inner {
        font-size: 0.9rem; /* Adjust as needed */
    }

	.hero-button {
		font-size: 0.8rem;
	}
	
}

/* Even smaller font size for screens narrower than 800px */
@media (max-width: 800px) {
    .hero-inner {
        font-size: 0.8rem; /* Adjust as needed */
    }

	.hero-button {
		font-size: 0.7rem;
	}

	.rocket-container {
		display: none; /* Hides the rocket container */
 	}

	.hero-content {
		width: 100%;
	}
}